import * as React from 'react';
import { Header } from '@fluentui/react-northstar';
import { withTeamsContext } from '../Providers/TeamsContextProvider';
import { Surfaces } from '../TeamsApp';
import SidePanel from './SidePanel';
import PreMeeting from './PreMeeting';

interface MeetingProps {
  teamsContext?: { frameContext: string };
}

const Meeting: React.FC<MeetingProps> = (props) => {
  const frameContext = props.teamsContext?.frameContext;
  switch (frameContext) {
    case Surfaces.SidePanel:
      return <SidePanel />
    case Surfaces.PreMeeting:
      return <PreMeeting />
    default:
      return <Header content="No teams context detected!" />
  }
}
export default withTeamsContext(Meeting);
