import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import TeamsApp from './TeamsApp';
import * as serviceWorker from './serviceWorker';
import * as msTeams from '@microsoft/teams-js';

msTeams.initialize();
ReactDOM.render(
  <TeamsApp/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
