import React, { Component, useContext } from 'react';
import * as msTeams from '@microsoft/teams-js';

const TeamsContext = React.createContext({});

class TeamsContextProvider extends Component {
    state = {
        teamsContext: {}
    };

    componentDidMount() {
        msTeams.getContext((context, error) => {
            this.setState({ teamsContext: context });
            if (error) {
              msTeams.appInitialization.notifyFailure(error);
            } else {
              msTeams.appInitialization.notifySuccess();
            }
        });
    }

    render() {
        return(
            <TeamsContext.Provider value={this.state.teamsContext}>
                {this.props.children}
            </TeamsContext.Provider>
        );
    }
}

export default TeamsContextProvider;

export const withTeamsContext = Component => props => {
    const teamsContext = useContext(TeamsContext);
    return <Component {...props} teamsContext={teamsContext} />
}
