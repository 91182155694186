import React from 'react';
import TeamsContextProvider from './Providers/TeamsContextProvider';
import App from './App';

const TeamsApp: React.FC<{}> = (props) => {
  return (
    <TeamsContextProvider>
      <App/>
    </TeamsContextProvider>
  );
}
export default TeamsApp;

export const Surfaces = Object.freeze({
  PreMeeting: "content",
  SidePanel: "sidePanel",
  Unknown: "unknown"
})
