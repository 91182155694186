import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from 'react-router-dom';
import * as msTeams from '@microsoft/teams-js';
import { Button, Header } from '@fluentui/react-northstar';
import { withTeamsContext } from './Providers/TeamsContextProvider';
import { Provider, teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from '@fluentui/react-northstar';
import Configure from './Configure';
import Meeting from './Meeting/Meeting';
import Tab from './Tab';

interface IProps {
  teamsContext?: { theme: string }
}

const App: React.FC<IProps> = (props: IProps) => {
  const [theme, setTheme] = useState(props.teamsContext?.theme);
  let themeFromContext = () => {
    switch (theme || props.teamsContext?.theme) {
      case "default":
        return teamsTheme;
      case "contrast":
        return teamsHighContrastTheme;
      case "dark":
      default:
        return teamsDarkTheme;
    };
  }

  msTeams.registerOnThemeChangeHandler(newTheme => {
    if (newTheme !== theme) {
      setTheme(newTheme);
    }
  });

  return (
    <Provider theme={themeFromContext()}>
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="container">
            <Header content="Sorenson Interpreting"/>
            <Button content="/configure" as={Link} to="/configure" primary/>
            <Button content="/meeting" as={Link} to="/meeting" primary/>
            <Button content="/tab" as={Link} to="/tab" primary/>
          </div>
        </Route>
        <Route path="/configure">
          <Configure/>
        </Route>
        <Route path="/meeting">
          <Meeting/>
        </Route>
        <Route path="/tab">
          <Tab/>
        </Route>
        <Route path="*">
          <NoMatch/>
        </Route>
      </Switch>
    </Router>
    </Provider>
  );
}
export default withTeamsContext(App);

const NoMatch = () => {
  let location = useLocation();
    return (
      <div>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    );
}
