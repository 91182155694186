import React, { Component } from "react";
import $ from 'jquery';
import 'jquery-json';
import { Video, Flex } from '@fluentui/react-northstar';

export default class InterpreterVideo extends Component {
  componentDidMount() {
    window.jQuery = $;
    window.$ = $;
    require('verto/src/jquery.verto');
    require('verto/src/jquery.FSRTC');
    require('verto/src/jquery.jsonrpcclient');

    this.vertoInit();
    console.log(`Element #id ${this.props.remoteViewId}`);
  }

  componentWillUnmount() {
    this.call = null;
    if (this.verto) {
      console.log("Hanging up call!");
      this.verto.hangup();
      this.verto = null;
    }
    this.confMan = null;
  }

  vertoInit() {
    this.call = null;
    this.verto = null;
    this.confMan = null;
    const fsDomain = process.env.REACT_APP_FREESWITCH_DOMAIN || 'svri.scis.biz'
    const wssUrl = process.env.REACT_APP_WSS_URL || 'wss://svri.scis.biz:8082'

    console.log(`Connecting to hostname ${fsDomain}`);
    console.log(`Connecting to web socket ${wssUrl}`);
    console.log(`this.props.remoteViewId ${this.props.remoteViewId}`);

    const callbacks = {
      onDialogState: (d) => {
        this.call = d;
        console.log("Call state " + d.state.name);
        switch (d.state) {
          case $.verto.enum.state.hangup:
            console.log("Call ended with cause: " + d.cause);
            break;
          default:
            break;
        };
      },
      onWSLogin: (v, success) => {
        if (success) {
          console.log("Logged in to web socket");
          this.call = null;
          console.log("Making a call");
          const dialstring = "18012077353";
          this.call = this.verto.newCall({
            destination_number: dialstring,
            caller_id_name: "Teams user",
            caller_id_number: "1008",
            useCamera: 'none',
            useMic: 'none',
            useSpeak: 'any',
            screenShare: false,
          });
        }
      },
      onWSClose: (v, success) => {
        if (success) {
          console.log("Web socket close");
        }
      },
      onEvent: (v, e) => {
        console.log("Event " + e);
      }
    }

    this.verto = new $.verto({
      login: `test@${fsDomain}`,
      passwd: '1234',
      socketUrl: wssUrl,
      tag: this.props.remoteViewId,
      ringFile: 'sounds/bell_ring2.wav',
      videoParams: {
        'minWidth': '1280',
        'minHeight': '720',
        'minFrameRate': 30
      },
      screenShare: true,
      useVideo: true,
      useStereo: true,
      audioParams: {
        googAutoGainControl: false,
        googNoiseSuppression: false,
        googHighpassFilter: false
      },
      iceServers: true
    }, callbacks);

    console.log(`vertoInit this.verto ${this.verto}`);
  }

  render() {
    return (
      <React.Fragment>
        <Flex column hAlign="center">
          <Video id={this.props.remoteViewId}
            autoPlay
            controls={false}
            width="100%" />
        </Flex>
      </React.Fragment>
    );
  }
}
