import React from 'react';
import { Header } from '@fluentui/react-northstar';
import * as msTeams from '@microsoft/teams-js';

export default function Configure() {
    msTeams.settings.setValidityState(true);
    msTeams.settings.registerOnSaveHandler(saveEvent => {
        msTeams.settings.setSettings({
            websiteUrl: "https://www.svrs.com",
            contentUrl: `${window.location.origin}/meeting`,
            entityId: "sidePanelLoad",
            suggestedDisplayName: "Sorenson Interpreting",
            meetingID: 1234567
        });
        saveEvent.notifySuccess();
    });

    return (
        <Header>Configure</Header>
    );
}
