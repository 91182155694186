import React from 'react';
import { Header } from '@fluentui/react-northstar';

const Tab = () => {
    return (
        <div align='center'>
            <Header content='Personal Tab'/>
        </div>
    );
};

export default Tab;
