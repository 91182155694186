import * as React from 'react';
import { Header, 
         List, 
         ListItem, 
         Avatar, 
         Card, 
         Checkbox, 
         Flex, 
         cardSelectableBehavior, 
         Text, 
         screenReaderContainerStyles } from '@fluentui/react-northstar';
import { withTeamsContext } from '../Providers/TeamsContextProvider';
import _ from 'lodash';

type SelectableCardsListActions =
  | { type: 'TOGGLE_ITEM'; selected: boolean; index: string }
  | { type: 'TOGGLE_ALL'; selected: boolean };

type SelectableCardsListState = Record<string, boolean>;

const selectableCardsListStateReducer: React.Reducer<SelectableCardsListState, SelectableCardsListActions> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'TOGGLE_ITEM': {
      return { ...state, [action.index]: action.selected };
    }
    case 'TOGGLE_ALL': {
      return _.mapValues(state, () => action.selected);
    }
  }
};

interface PreMeetingProps {
  teamsContext?: { meetingId: string };
}

const PreMeeting: React.FC<PreMeetingProps> = (props) => {
  const cardsNumber = 12;
  const cards = Array(cardsNumber)
    .fill(undefined)
    .map((item, index) => ({ index: index + 1, title: `User ${index + 1}` }));
  const initialState: SelectableCardsListState = cards.reduce<Record<string, boolean>>((cards, card) => {
    cards[card.index] = false;
    return cards;
  }, {});
  const [state, dispatch] = React.useReducer(selectableCardsListStateReducer, initialState);
  return(
    <div>
      <Header content="Pre Meeting Page"/>
      <Header as="h3" content={`meetingId ${props.teamsContext?.meetingId}`}/>
      <List>
        {cards.map(card => {
          const selectableCard = (<SelectableCard 
                                    key={card.index} 
                                    index={card.index} 
                                    title={card.title} 
                                    aria-label={`${card.title} ${card.index} of ${cardsNumber}`}
                                    onClick={() => {
                                      dispatch({ type: 'TOGGLE_ITEM', selected: !state[card.index], index: `${card.index}` });
                                    }}
                                    selected={state[card.index]}/>);
          return (
            <ListItem index={0} content={selectableCard} styles={{ paddingBottom: '0.5em'}}/>    
          );
        })}
      </List>
    </div>
  );
}

type SelectableCardProps = {
  index?: number;
  title?: string;
  selected?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
};

const SelectableCard: React.FC<SelectableCardProps> = ({ title, index, selected, onClick, ...rest}) => {
  const selectedMessageId = `selectedMessageId${index}`;
  const selectedMessage = 'selected';
  const notSelectedMessage = 'not selected';
  return (
    <Card
    id={`card${index}`}
    accessibility={cardSelectableBehavior}
    aria-labelledby={`card${index} ${selectedMessageId}`}
    aria-roledescription="user card avatar"
    onClick={onClick}
    selected={selected}
    {...rest}>
      <Card.Header>
        <Text content={title} weight="bold" size="medium" />
      </Card.Header>
      <Card.TopControls>
        <Checkbox checked={selected} onClick={onClick} />
        <div id={selectedMessageId} style={screenReaderContainerStyles} aria-live="polite" role="presentation">
          {selected ? selectedMessage : notSelectedMessage}
        </div>
      </Card.TopControls>
      <Card.Body>
        <Flex column gap="gap.small">
          <Avatar name={title}/>
        </Flex>
      </Card.Body>
    </Card>
  );
}

export default withTeamsContext(PreMeeting);